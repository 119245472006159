var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 h-50 bg-light" },
    [
      _c("CEmbed", {
        staticStyle: {},
        attrs: {
          type: "iframe",
          src: _vm.myUrl,
          width: "100%",
          height: "1024",
          ratio: "1by1"
        }
      }),
      _c(
        "l-map",
        {
          staticStyle: { height: "100%", width: "100%" },
          attrs: { zoom: _vm.zoom, center: _vm.center }
        },
        [
          _c("l-tile-layer", {
            attrs: { url: _vm.url, attribution: _vm.attribution }
          }),
          _c("l-marker", { attrs: { "lat-lng": _vm.marker } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }