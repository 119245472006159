var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { lg: "12" } },
        [
          _vm.data
            ? _c("k-gauge", {
                attrs: {
                  value: _vm.data.value,
                  width: _vm.data.width,
                  height: _vm.data.height,
                  max: _vm.data.max,
                  title: _vm.data.title
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }