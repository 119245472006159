

<template>
<CRow>
<CCol lg=12>
    <k-gauge v-if="data"
    :value="data.value"
    :width="data.width"
    :height="data.height"
    :max="data.max"
    :title="data.title"
    
    >
    </k-gauge>
</CCol>
</CRow>
</template>

<script>

/** A Wrapper around KGauge-Vue library by @kagronick/kgauge-vue
 * We might want the option to switch to just-gage-vue
 * So we made a wrapper component around the most relavent items 
 */

const defaultSettings = {
             value: 30,
            width: "100%",
            height: "100%",
            min: 0,
            max: 300,
            title: "",
            colorSteps: [], 
            gaugeSize: 2,
            precision: 2, 
        };
import KGauge from "@kagronick/kgauge-vue"
export default {
    name: "GuageWrapper",
    components: {
        KGauge,
    },
    props:{
        //value: Number,
        data: {
            type: Object, 
            defaults: defaultSettings,
        }
    },
    data (){
        return {

        };
    },
    methods: {

    }
}
</script>