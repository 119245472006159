var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade" } },
    [
      _vm.show
        ? _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c(
                "CCardHeader",
                {
                  staticClass: "dashboard-card-header mt-1 p-1",
                  attrs: { color: "transparent" }
                },
                [
                  _vm._t("header"),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _c(
                        "CLink",
                        {
                          staticClass: "card-header-action btn-close",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              _vm.show = false
                            }
                          }
                        },
                        [
                          _c("CIcon", {
                            staticStyle: { color: "white" },
                            attrs: { name: "cil-x-circle" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _c(
                "CScrollbar",
                [
                  _c(
                    "CCardBody",
                    { staticClass: "overflow-auto", attrs: { color: "grey" } },
                    [_vm._t("body")],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }