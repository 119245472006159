<template>

  <div class="w-100 h-50 bg-light">
    <CEmbed
    style="map-container"
    type="iframe"
              :src="myUrl"
              width="100%"
              height="1024"
              ratio="1by1"

    
    >
    </CEmbed>
    <l-map
      :zoom="zoom"
      :center="center"
      style="height: 100%; width: 100%"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-marker :lat-lng="marker" />
      <!--l-icon-default :image-path="path" / -->
    </l-map>
  </div>
</template>

<style scoped>
.map-container{
  margin: 0 auto;
  padding: 0;
}
</style>

<script>
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LIconDefault } from "vue2-leaflet";

export default {
  name: "MapWrapper",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault
  },
  props:{
    data: Object,
    //center: Array,
    //marker: Array,
  },
  data() {
    return {
      urlPath: this.myUrl,
      zoom: 13,
      path: "/images/",
      //bounds:[],
      center: [47.41322, -1.219482],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 	"https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      //marker: [47.41322, -1.219482]
      marker: [47.41322, -1.219482],
    }
    
  },

  computed:
  {
    myUrl(){

      var ret = "https://demo1.aarcomm.io/api/grafana/d-solo/1l9374vMz/my-fake-dash?orgId=1&from=&to=now&panelId=8";
      
      if(this.data && this.data.device)
        ret += "&var-TruckID=" + this.data.device;  

      return ret; 
      //return "https://demo1.aarcomm.io/api/grafana/d-solo/1l9374vMz/my-fake-dash?orgId=1&from=&to=now&panelId=8"
    }
  },
  mounted: function(){
    this.zoom = 10;
  }
};
</script>
