


<template>
<transition name="fade">
  <CCard v-if="show" class="h-100" >
    <CCardHeader class="dashboard-card-header mt-1 p-1" color="transparent">
      <slot name="header"></slot>
      <div class="card-header-actions">
      <CLink href="#" class="card-header-action btn-close" v-on:click="show = false">
                  <CIcon style="color:white" name="cil-x-circle"/>

      </CLink>
      </div>
    </CCardHeader>
    <CScrollbar>
      <CCardBody color="grey" class="overflow-auto">
        <slot name="body" ></slot>
      </CCardBody>
    </CScrollbar>
  </CCard>
</transition>
</template>




<style >

.card-body{
  width: 100%;
}
</style>


<script>
export default {
  name: "RemovableDashboardCard",
  props: {},

  data() {
    return {
      show: true,
      isCollapsed: true,
    };
  },
};
</script>

